@import url(https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Serif:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap);
@import url(//db.onlinewebfonts.com/c/800e985f75a5a56c2f7b4be502d286de?family=Burbank+Big+Condensed);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Varela Round', sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase
}

@font-face {
  font-family: "Burbank Big Condensed";
  src: url("//db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.eot");
  src: url("//db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.woff") format("woff"), url("//db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.svg#Burbank Big Condensed") format("svg");
}

body {
  overflow-x: hidden;
}

.mintBtn--2:hover {
  background-color: #8a6d57;
  color: white;
}

.logoL {
  width: 300px;
  height: 286px;
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #242424;
  z-index: 5000000000000000;
  position: fixed;
}

.page-loader .txt {
  color: #ffffff;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: black 3px 5px 2px;
  -webkit-text-stroke: 1.5px black;
  font-size: 50px;
}

.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.loading-area {
  display: grid;
  place-items: center;
  height: 100vh;
}

.loader div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: animate 1.5s ease-in-out infinite;
          animation: animate 1.5s ease-in-out infinite;
  display: inline-block;
  margin: .5rem;
}

.loader div:nth-child(0) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.loader div:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.loader div:nth-child(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.loader div:nth-child(3) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.loader div:nth-child(4) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.loader div:nth-child(5) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.loader div:nth-child(6) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.loader div:nth-child(7) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

@-webkit-keyframes animate {

  0%,
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    background-color: #d3e8ec;
  }

  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #8a6d57;
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #516479;
  }
}

@keyframes animate {

  0%,
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    background-color: #d3e8ec;
  }

  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #8a6d57;
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #516479;
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

@media screen and (max-width: 499px) {

  .connect2btns{
    display: flex;
  }
  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
   }
    .mm {
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob{
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
      background-color: #3d3d3d67;
      width: 100px;
      border-radius: 50px;
      border: white 1px solid;
       cursor: pointer;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
  
     }
    
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff81;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 80%;
  }

  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 22px;
    cursor: pointer;
    border-top: #88c1ff 1px solid;

  }

  .littleNav {
    background-color: #2f3a47;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #fabb0e solid;
    opacity: 0;
    -webkit-animation: fadeInLeft 0.6s forwards;
            animation: fadeInLeft 0.6s forwards;
  }

  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 35px;
  }

  .cursor {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;
  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 15px;
    font-size: 16px;

    border-width: 2px;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 5px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 38%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 110s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1rem;
    font-size: 18px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 4.5rem;
    background-color: #f5f1ec;
    border-bottom: 4px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 4.5rem;
    line-height: 4.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 280s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 0.5rem;
    font-size: 35px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 16%;
    background-color: #415774;
    border-top: 4px solid black;
  }

  .faqBg {
    padding-top: 16%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 16%;
    padding-bottom: 16%;
    background-color: #d1cb87;
    border-top: 4px solid black;
  }

  .boxWrap2Mint {
    padding-top: 16%;
    padding-bottom: 16%;
    background-color: #abcbed;
    border-top: 4px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 16%;
    background-color: #a7c8ee;
    border-top: 4px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 4px solid black;
    background-color: #01a5c2;
  }

  .tableH {
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 60px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .tableH-2 {
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 60px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;

  }


  .tableH2 {
    font-weight: bolder;
    font-size: 14px;
  }

  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 22px;
    border-radius: 5px;
    background-color: #000000;
    border: 2px #000000 solid;
    color: white;
    padding-top: 10px;
    transition: 0.2s ease-in-out;
  }

  .tableBtn img {
    width: 32px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 16%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-bottom: 4px black solid;
  }

  .tableNFT1 {
    width: 60%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 60%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 16%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .table1-1 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conTInMain {
    font-size: 65px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 65px;
    text-align: center;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .conT2Main {
    font-size: 65px;
    line-height: 65px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 65px;
    line-height: 65px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    color: #ffffff;
    text-align: center;
    font-size: 60px;
    line-height: 60px;
    font-family: "Burbank Big Condensed";
    text-transform: uppercase;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 40%;
    margin-left: 5%;
  }

  .nft2 {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    display: flex;
  }

  .radio {
    width: 35%;
    height: 35%;
    margin-right: 5%;
    margin-top: 70px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
  font-size: 35px;
  color: white;
  letter-spacing: 2px;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: 0 0 10px #def99e;
}*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 13px;
    width: 100%;
    color: white;
    z-index: 1000;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConIn p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .book {
    width: 40%;
    margin-top: 4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 40px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 18px;
    text-align: center;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 13px;
  }

  .basketBall {
    width: 80px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 20px;
    border: 4px solid black;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .mintBox--1 {
    border-radius: 80px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .mintBtn {
    font-size: 30px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .mintBtn--2 {
    font-size: 30px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
  }

  .slideImg {
    position: relative;
    width: 450px;
    height: 450px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .slideImg2 {
    position: relative;
    width: 90%;
    height: 300px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 30px;

  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 25px;
    margin-left: 30px;
    margin-right: 30px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 65px;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: black;
    padding-top: 10px;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 15px;
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    line-height: 55px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 55px;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 28px;
    height: 28px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 25px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 80px;
    padding-right: 80px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 4px solid;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .connect2btns{
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
   }
    .mm {
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob{
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
      background-color: #3d3d3d67;
      width: 100px;
      border-radius: 50px;
      border: white 1px solid;
       cursor: pointer;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
  
     }
    
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff81;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 80%;
  }

  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 22px;
    cursor: pointer;
    border-top: #88c1ff 1px solid;

  }

  .littleNav {
    background-color: #2f3a47;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #fabb0e solid;
    opacity: 0;
    -webkit-animation: fadeInLeft 0.6s forwards;
            animation: fadeInLeft 0.6s forwards;
  }

  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 35px;
  }

  .cursor {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;

  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 15px;
    font-size: 16px;

    border-width: 2px;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 5px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 25%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 110s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1rem;
    font-size: 18px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    background-color: #f5f1ec;
    border-bottom: 4px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 280s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1rem;
    font-size: 60px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 4px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 4px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 4px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 4px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 4px solid black;
    background-color: #01a5c2;
  }

  .tableH {
    font-size: 70px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 70px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .tableH-2 {
    font-size: 70px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 70px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;

  }


  .tableH2 {
    font-weight: bolder;
    font-size: 15px;
  }

  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 25px;
    border-radius: 5px;
    background-color: #000000;
    border: 2px #000000 solid;
    color: white;
    padding-top: 10px;
    transition: 0.2s ease-in-out;
  }

  .tableBtn img {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-bottom: 4px black solid;
  }

  .tableNFT1 {
    width: 50%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 50%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .table1-1 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conTInMain {
    font-size: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 90px;
    text-align: center;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .conT2Main {
    font-size: 90px;
    line-height: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 90px;
    line-height: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    color: #ffffff;
    text-align: center;
    font-size: 80px;
    line-height: 80px;
    font-family: "Burbank Big Condensed";
    text-transform: uppercase;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 30%;
    margin-left: 5%;
  }

  .nft2 {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    display: flex;
  }

  .radio {
    width: 30%;
    height: 30%;
    margin-right: 5%;
    margin-top: 50px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
  font-size: 35px;
  color: white;
  letter-spacing: 2px;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: 0 0 10px #def99e;
}*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 15px;
    width: 90%;
    color: white;
    z-index: 1000;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConIn p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .book {
    width: 40%;
    margin-top: 4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 70px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 20px;
    text-align: center;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 48px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 14px;
  }

  .basketBall {
    width: 100px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 20px;
    border: 4px solid black;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .mintBox--1 {
    border-radius: 80px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

  }

  .mintBtn {
    font-size: 35px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }

  .mintBtn--2 {
    font-size: 35px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
  }

  .slideImg {
    position: relative;
    width: 450px;
    height: 450px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .slideImg2 {
    position: relative;
    width: 90%;
    height: auto;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 30px;
    display: block;
    height: 400px;
  }


  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 35px;
    margin-left: 40px;
    margin-right: 40px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 90px;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10px;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    line-height: 80px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 80px;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 38px;
    height: 38px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 30px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 4px solid;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .connect2btns{
    display: flex;
  }

  .connect2-2-Mob-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #3d3d3d67;
    width: 100px;
    border-radius: 50px;
    border: white 1px solid;
    margin-left: 20px;
    cursor: pointer;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 2px;
   }
    .mm {
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob{
      width: 30px;
      height: 30px;
    }
  
    .connect2-2-Mob {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
      background-color: #3d3d3d67;
      width: 100px;
      border-radius: 50px;
      border: white 1px solid;
       cursor: pointer;
      color: white;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
  
     }
    
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 50%;
  }

  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ffffff81;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 22px;
    cursor: pointer;
    border-top: #88c1ff 1px solid;

  }

  .littleNav {
    background-color: #2f3a47;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #fabb0e solid;
    opacity: 0;
    -webkit-animation: fadeInLeft 0.6s forwards;
            animation: fadeInLeft 0.6s forwards;
  }

  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 35px;
  }

  .cursor {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;
  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 15px;
    font-size: 16px;

    border-width: 2px;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 5px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 15%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 110s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1rem;
    font-size: 18px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    background-color: #f5f1ec;
    border-bottom: 4px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 280s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1rem;
    font-size: 60px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 4px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 4px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 4px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 4px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 4px solid black;
    background-color: #01a5c2;
  }

  .tableH {
    font-size: 70px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 70px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .tableH-2 {
    font-size: 70px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 70px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;

  }


  .tableH2 {
    font-weight: bolder;
    font-size: 15px;
  }

  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 25px;
    border-radius: 5px;
    background-color: #000000;
    border: 2px #000000 solid;
    color: white;
    padding-top: 10px;
    transition: 0.2s ease-in-out;
  }

  .tableBtn img {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-bottom: 4px black solid;
  }

  .tableNFT1 {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
  }


  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .table1-1 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conTInMain {
    font-size: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 90px;
    text-align: center;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .conT2Main {
    font-size: 90px;
    line-height: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 90px;
    line-height: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    color: #ffffff;
    text-align: center;
    font-size: 80px;
    font-family: "Burbank Big Condensed";
    text-transform: uppercase;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 30%;
    margin-left: 5%;
  }

  .nft2 {
    margin-left: 5%;
  }

  .radio {
    width: 30%;
    height: 30%;
    margin-right: 5%;
    margin-top: 110px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
  font-size: 35px;
  color: white;
  letter-spacing: 2px;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: 0 0 10px #def99e;
}*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: left;
    font-size: 20px;
    margin-top: -40px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 15px;
    width: 90%;
    color: white;
    z-index: 1000;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConIn p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .book {
    width: 30%;
    margin-top: 4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 75px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 22px;
    text-align: center;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 48px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 14px;
  }

  .basketBall {
    width: 100px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 20px;
    border: 4px solid black;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .mintBox--1 {
    border-radius: 80px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

  }

  .mintBtn {
    font-size: 35px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }
  
  .mintBtn--2 {
    font-size: 35px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }

  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;
  }

  .slideImg {
    position: relative;
    width: 450px;
    height: 450px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .slideImg2 {
    position: relative;
    width: 90%;
    height: 650px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 40px;

  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 35px;
    margin-left: 40px;
    margin-right: 40px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 90px;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }


  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 10px;
  }

  /* loading dots */

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    line-height: 90px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 90px;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 42px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 4px solid;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .connect2-2-Mob {
    display: none;
  }

  .slideImg2 {
    display: none;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 40%;
  }

  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'Varela Round', sans-serif;
  }

  .cursor {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;

  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 15px;
    font-size: 16px;

    border-width: 2px;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 5px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 32%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 150s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    background-color: #f5f1ec;
    border-bottom: 4px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 470s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 80px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 4px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 4px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 4px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 4px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 4px solid black;
    background-color: #01a5c2;
  }

  .tableH {
    font-size: 80px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 80px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .tableH-2 {
    font-size: 80px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 80px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }


  .tableH2 {
    font-weight: bolder;
    font-size: 15px;
  }

  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 25px;
    border-radius: 5px;
    background-color: #000000;
    border: 2px #000000 solid;
    color: white;
    padding-top: 10px;
    transition: 0.2s ease-in-out;
  }

  .tableBtn img {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-right: 2px black solid;
  }

  .tableNFT1 {
    width: 50%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 50%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-left: 2px black solid;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conTInMain {
    font-size: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 90px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .conT2Main {
    font-size: 90px;
    line-height: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 90px;
    line-height: 90px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    color: #ffffff;
    text-align: center;
    font-size: 90px;
    font-family: "Burbank Big Condensed";
    text-transform: uppercase;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 20%;
    margin-left: 5%;
    margin-top: -10%;
  }

  .nft2 {
    margin-left: 5%;
  }

  .radio {
    width: 20%;
    height: 20%;
    margin-right: 5%;
    margin-top: 20px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
  font-size: 35px;
  color: white;
  letter-spacing: 2px;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: 0 0 10px #def99e;
}*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: left;
    font-size: 23px;
    margin-top: -40px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 15px;
    width: 55%;
    color: white;
    z-index: 1000;
  }

  .storyConIn p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .book {
    width: 20%;
    margin-top: 4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 15px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 90px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 25px;
    text-align: center;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 48px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 14px;
  }

  .basketBall {
    width: 100px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
    margin-bottom: 10%;

  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 20px;
    border: 4px solid black;
    width: 45%;
    margin-left: 20px;
  }

  .mintBox--1 {
    border-radius: 80px;
    width: 45%;
    margin-left: 20px;
 
  }

  .mintBtn {
    font-size: 35px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }
  
  .mintBtn--2 {
    font-size: 35px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10vh;
    padding: 20px;
  }

  .slideImg {
    position: relative;
    width: 450px;
    height: 450px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 35px;
    margin-left: 40px;
    margin-right: 40px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 90px;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    line-height: 110px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 90px;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 45px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 4px solid;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .slideImg2 {
    display: none;
  }
  .connect2-2-Mob {
    display: none;
  }
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'Varela Round', sans-serif;
  }

  .cursor {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;

  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 35px;
    margin-right: 35px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 15px;
    font-size: 16px;

    border-width: 2px;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 5px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 32%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 150s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    background-color: #f5f1ec;
    border-bottom: 4px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 470s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 80px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 4px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 4px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 4px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 4px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 4px solid black;
    background-color: #01a5c2;
  }

  .tableH {
    font-size: 110px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 110px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .tableH-2 {
    font-size: 110px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 110px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }


  .tableH2 {
    font-weight: bolder;
    font-size: 16px;
  }

  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    border-radius: 5px;
    background-color: #000000;
    border: 2px #000000 solid;
    color: white;
    padding-top: 10px;
    transition: 0.2s ease-in-out;
  }

  .tableBtn img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-right: 2px black solid;
  }

  .tableNFT1 {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-left: 2px black solid;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conTInMain {
    font-size: 130px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 130px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .conT2Main {
    font-size: 130px;
    line-height: 125px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 130px;
    line-height: 125px;
    color: white;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    color: #ffffff;
    text-align: center;
    font-size: 130px;
    font-family: "Burbank Big Condensed";
    text-transform: uppercase;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 20%;
    margin-left: 5%;
    margin-top: -10%;
  }

  .nft-2 {
    width: 20%;
    margin-left: 5%;
    margin-top: -10%;
     }

  .nft2 {
    margin-left: 5%;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 35%;
  }

  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .radio {
    width: 20%;
    height: 20%;
    margin-right: 5%;
    margin-top: 20px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
  font-size: 35px;
  color: white;
  letter-spacing: 2px;
  font-family: "Burbank Big Condensed";
  z-index: 1;
  text-align: center;
  text-shadow: 0 0 10px #def99e;
}*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: left;
    font-size: 25px;
    margin-top: -60px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 16px;
    width: 55%;
    color: white;
    z-index: 1000;
  }

  .storyConIn p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .book {
    width: 20%;
    margin-top: -4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 100px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 30px;
    text-align: center;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 60px;
    line-height: 60px;
    width: 500px;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    width: 400px;
    font-weight: bolder;
    margin-top: 5px;
  }

  .basketBall {
    width: 120px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
    margin-bottom: 10%;
  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 20px;
    border: 4px solid black;
    width: 45%;
    margin-left: 20px;
  }


  .mintBox--1 {
    border-radius: 80px;
    width: 45%;
    margin-left: 20px;
  }

  .mintBtn {
    font-size: 45px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }
  
  .mintBtn--2 {
    font-size: 45px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 4px rgb(0, 0, 0) solid;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 15px;
    padding-bottom: 5px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10vh;
    padding: 20px;

  }

  .slideImg {
    position: relative;
    width: 550px;
    height: 550px;
    overflow: hidden;
    border-radius: 20px;
    border: 4px solid black;
  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 40px;
    margin-right: 40px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 130px;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    z-index: 1;
    text-align: center;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    line-height: 110px;
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 130px;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 60px;
    height: 60px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 50px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 4px solid;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .slideImg2 {
    display: none;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 35%;
  }
  .connect2-2-Mob {
    display: none;
  }
  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 22px;
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'Varela Round', sans-serif;

  }

  .cursor {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;

  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 20px;
    font-size: 22px;

    border-width: 4px;
    letter-spacing: 3px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 8px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 32%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5.5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 7px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5.5rem;
    line-height: 5.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 140s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 28px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 9.5rem;
    background-color: #f5f1ec;
    border-bottom: 6px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 9.5rem;
    line-height: 9.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 450s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 2rem;
    font-size: 100px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 6px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 6px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 6px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 6px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 6px solid black;
    background-color: #01a5c2;
  }


  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 45px;
    border-radius: 10px;
    background-color: #000000;
    border: 4px #000000 solid;
    color: white;
    padding-top: 10px;
    transition: 0.2s ease-in-out;
  }

  .tableBtn img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-right: 3px black solid;
  }

  .tableNFT1 {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-left: 3px black solid;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .tableH-2 {
    font-size: 150px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 150px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2px black;
  }

  .tableH {
    font-size: 150px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 150px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2px black;
  }

  .tableH2 {
    font-weight: bolder;
    font-size: 22px;
  }

  .conTInMain {
    font-size: 170px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 170px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2.5px black;
  }

  .conT2Main {
    font-size: 170px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 170px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2.5px black;
    text-align: center;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 170px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 170px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2.5px black;
    text-align: center;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    font-size: 170px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 170px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2.5px black;
    text-align: center;
    text-transform: uppercase;

  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 20%;
    margin-left: 5%;
    margin-top: -10%;
  }

  .nft2 {
    margin-left: 5%;
  }

  .radio {
    width: 20%;
    height: 20%;
    margin-right: 5%;
    margin-top: 20px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: left;
    font-size: 35px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 22px;
    width: 55%;
    color: white;
    z-index: 1000;
  }

  .storyConIn p {
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .book {
    width: 20%;
    margin-top: -4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 140px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 40px;
    text-align: center;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 90px;
    line-height: 90px;
    width: 90%;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    width: 60%;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 23px;
  }

  .basketBall {
    width: 160px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
    margin-bottom: 10%;

  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 25px;
    border: 6px solid black;
    width: 45%;
    margin-left: 35px;
  }

  .mintBox--1 {
    border-radius: 85px;
    width: 45%;
    margin-left: 35px;
  }

  .mintBtn {
    font-size: 60px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 6px rgb(0, 0, 0) solid;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 20px;
    padding-bottom: 10px;
  } 
  
  .mintBtn--2 {
    font-size: 60px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 6px rgb(0, 0, 0) solid;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
     margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
     padding-bottom: 10px;
     margin-top: -80px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10vh;
    padding: 20px;

  }

  .slideImg {
    position: relative;
    width: 750px;
    height: 750px;
    overflow: hidden;
    border-radius: 20px;
    border: 6px solid black;
  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 28px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    margin-top: 50px;
    margin-bottom: 70px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 45px;
    margin-left: 50px;
    margin-right: 50px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 170px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 170px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2.5px black;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    text-align: center;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 23px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 28px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 28px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 28px;
    color: rgb(0, 0, 0);
    padding: 2.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 28px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 15px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-shadow: black 3px 5px 2px;
    -webkit-text-stroke: 1.5px black;
    color: #88c1ff;
    font-size: 170px;
    line-height: 170px;
    z-index: 1;
    text-shadow: black 5px 7px 3px;
    -webkit-text-stroke: 2.5px black;
    text-align: center;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 65px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
  background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 6px solid;
    border-radius: 13px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .slideImg2 {
    display: none;
  }

  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 35%;
  }
  .connect2-2-Mob {
    display: none;
  }
  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 30px;
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'Varela Round', sans-serif;
  }

  .cursor {
    position: absolute;
    width: 70px;
    height: 70px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;

  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 55px;
    margin-right: 55px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 30px;
    font-size: 30px;

    border-width: 6px;
    letter-spacing: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 12px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 32%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 10px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 110s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 40px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 14rem;
    background-color: #f5f1ec;
    border-bottom: 8px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 14rem;
    line-height: 14rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 450s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 4rem;
    font-size: 135px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 8px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 8px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 8px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 8px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 12px solid black;
    background-color: #01a5c2;
  }


  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 60px;
    border-radius: 13px;
    background-color: #000000;
    border: 6px #000000 solid;
    color: white;
    padding-top: 13px;
    transition: 0.2s ease-in-out;
    margin-top: 10px;

  }

  .tableBtn img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-right: 4px black solid;
  }

  .tableNFT1 {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .tableNFT1- {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-left: 4px black solid;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .tableH-2 {
    font-size: 195px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 195px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
  }

  .tableH {
    font-size: 195px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 195px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
  }

  .tableH2 {
    font-weight: bolder;
    font-size: 30px;
  }

  .conTInMain {
    font-size: 235px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 235px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
  }

  .conT2Main {
    font-size: 235px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 235px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 235px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 235px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    font-size: 235px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 235px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
    text-align: center;
    text-transform: uppercase;

  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 20%;
    margin-left: 5%;
    margin-top: -10%;
  }

  .nft2 {
    margin-left: 5%;
  }

  .radio {
    width: 20%;
    height: 20%;
    margin-right: 5%;
    margin-top: 20px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: left;
    font-size: 45px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 31px;
    width: 55%;
    color: white;
    z-index: 1000;
  }

  .storyConIn p {
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .book {
    width: 20%;
    margin-top: -4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 180px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 50px;
    text-align: center;
    margin-top: 20px;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 110px;
    line-height: 110px;
    width: 90%;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    width: 60%;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 30px;
  }

  .basketBall {
    width: 160px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;

  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 30px;
    border: 8px solid black;
    width: 45%;
    margin-left: 50px;
    padding: 15px;
  }

  .mintBox--1 {
    border-radius: 90px;
    width: 45%;
    margin-left: 50px;
    padding: 15px;
  }

  .mintBtn {
    font-size: 85px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 8px rgb(0, 0, 0) solid;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 20px;
    padding-bottom: 10px;
  }
  
  .mintBtn--2 {
    font-size: 85px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 8px rgb(0, 0, 0) solid;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
     margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: -120px;
    padding-bottom: 10px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10vh;
    padding: 20px;

  }

  .slideImg {
    position: relative;
    width: 925px;
    height: 925px;
    overflow: hidden;
    border-radius: 30px;
    border: 8px solid black;
  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 37px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    margin-top: 50px;
    margin-bottom: 70px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 60px;
    margin-right: 60px;
    width: 120px;
    height: 120px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 5px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 235px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 235px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    text-align: center;
  }

  .mintbuttondiv {
    text-align: center;

  }


  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 40px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 37px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 37px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 38px;
    color: rgb(0, 0, 0);
    padding: 3.5rem;
    margin-bottom: 5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    padding-bottom: 20px;
    text-align: left;
    font-size: 38px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    z-index: 1;
    font-size: 235px;
    line-height: 235px;
    z-index: 1;
    text-shadow: black 7px 8px 5px;
    -webkit-text-stroke: 3px black;
    z-index: 1;
    text-align: center;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 85px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
  background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 8px solid;
    border-radius: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}

@media screen and (min-width: 3840px) {
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    background-color: #ffffff81;
    z-index: 1000000;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }
  .connect2-2-Mob {
    display: none;
  }
  .nft2-faq {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 35%;
  }

  .nft2-faqDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    /* Add this line to right-align the image */
  }

  .slideImg2 {
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 45px;
    cursor: pointer;
    z-index: 10000;
    color: #000000;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: 'Varela Round', sans-serif;
  }

  .cursor {
    position: absolute;
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    top: var(--y, 0);
    left: 0;
    left: var(--x, 0);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    mix-blend-mode: difference;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
    display: none;

  }

  @-webkit-keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  #fontSize:hover {
    color: #ffbb00;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    margin-left: 95px;
    margin-right: 95px;
  }

  .connectBtn {
    background-color: #000000;
    color: #b6bcbe;
    padding: 40px;
    font-size: 45px;

    border-width: 8px;
    letter-spacing: 5px;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 15px;
    font-family: 'Varela Round', sans-serif;
  }

  .connectBtn:hover {
    color: #ffffff;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .galleryMain {
    background-color: #eddfd1;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .cont {
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat fixed;
    background-size: cover;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    height: 100vh;
    width: 100%;
  }

  .introduction {
    width: 100%;
    background: url(/static/media/banner.2dd6872f.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

  }

  .logo {
    width: 32%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 12rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 15px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #f13ff1, #ffdfff);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 12rem;
    line-height: 12rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 4rem;
    font-size: 57px;
    color: #e3a8f0;
    font-weight: bold;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 20rem;
    background-color: #f5f1ec;
    border-bottom: 12px #516479 solid;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 20rem;
    line-height: 20rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 350s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 6rem;
    font-size: 200px;
    color: #516479;
    font-family: "Burbank Big Condensed";

  }

  /*TICKER 2*/

  .banner {
    width: 100%;
    background-size: cover;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background-color: #415774;
    border-top: 12px solid black;
  }

  .faqBg {
    padding-top: 7%;
    background-color: #ffd9d9;
  }

  .boxWrap2Story2 {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #d1cb87;
    border-top: 12px solid black;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: #abcbed;
    border-top: 12px rgb(0, 0, 0) solid;
  }

  .boxWrapBuy {
    padding-top: 7%;
    background-color: #a7c8ee;
    border-top: 12px solid black;
  }

  .boxWrap2Footer {
    background-color: #34404d;
  }

  .main2 {
    border-bottom: 12px solid black;
    background-color: #01a5c2;
  }


  .tableBtn {
    font-family: "Burbank Big Condensed";
    font-size: 80px;
    border-radius: 16px;
    background-color: #000000;
    border: 10px #000000 solid;
    color: white;
    padding-top: 16px;
    transition: 0.2s ease-in-out;
    margin-top: 15px;

  }

  .tableBtn img {
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .tableBtn:hover {
    background-color: #747474;
  }

  .table2Main {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .table1 {
    background-color: #ffdfaf;
    flex-flow: column nowrap;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    border-right: 6px black solid;
  }

  .tableNFT1 {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  .tableNFT1- {
    width: 40%;
    justify-content: flex-end;
    margin-left: auto;
  }

  .table2 {
    background-color: #c6ac9b;
    width: 100%;
    padding-top: 7%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-left: 6px black solid;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .table1-1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutIn {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    text-align: center;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .tableH-2 {
    font-size: 290px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 290px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 4px black;
  }

  .tableH {
    font-size: 290px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 290px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 4px black;
  }

  .tableH2 {
    font-weight: bolder;
    font-size: 50px;
  }

  .conTInMain {
    font-size: 350px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 350px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 5px black;
  }

  .conT2Main {
    font-size: 350px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 350px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 5px black;
    margin-bottom: 40px;
  }

  .conT3Main {
    font-size: 350px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 350px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 5px black;
    margin-bottom: 40px;
  }

  .in17 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in18 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }


  .in19 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }


  .in1 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;

  }

  .in8 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
  }


  .in2 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in3 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in4 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in5 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in6 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in7 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in9 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;

  }

  .in16 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in10 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;

  }

  .in11 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;

  }

  .in12 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;

  }

  .in13 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;

  }

  .in14 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;

  }

  .in15 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }


  .in20 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in21 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in22 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in23 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in24 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in25 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in26 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in27 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in28 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in29 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in30 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in31 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in32 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in33 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in34 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  /* Repeat the pattern for other span elements */


  .in35 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .in36 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  .in37 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }

  .in38 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

  .in39 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.10s;
            animation-delay: 0.10s;
  }

  .in40 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.12s;
            animation-delay: 0.12s;
  }


  .in41 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.14s;
            animation-delay: 0.14s;

  }

  .in42 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }


  .in43 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;

  }

  .in44 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.20s;
            animation-delay: 0.20s;

  }

  .in45 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.22s;
            animation-delay: 0.22s;

  }

  .in46 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;

  }

  .in47 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.26s;
            animation-delay: 0.26s;
  }

  .in48 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.28s;
            animation-delay: 0.28s;
  }

  .in49 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

  }

  .in50 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;

  }

  .in51 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy 1s;
            animation: waviy 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .in52 {
    font-family: "Burbank Big Condensed";
    -webkit-animation: waviy-S 1s;
            animation: waviy-S 1s;
    -webkit-animation-delay: 0.34s;
            animation-delay: 0.34s;

  }

  .pop-up {
    display: inline-block;
    -webkit-animation: pop-up 0.5s ease-in-out forwards;
            animation: pop-up 0.5s ease-in-out forwards;
    opacity: 0;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    70% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  @-webkit-keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  @keyframes pop-up {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }

    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }


  .storyConDiv {
    font-size: 350px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 350px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 5px black;
    text-align: center;
    text-transform: uppercase;

  }

  .mainImgs {
    display: flex;
    justify-content: space-between;
  }

  .nft {
    width: 20%;
    margin-left: 5%;
    margin-top: -10%;
  }

  .nft2 {
    margin-left: 5%;
  }

  .radio {
    width: 20%;
    height: 20%;
    margin-right: 5%;
    margin-top: 20px;
    -webkit-animation: shake 5s infinite;
            animation: shake 5s infinite;

  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    10% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    20% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    30% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    40% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    50% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    60% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    70% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    80% {
      -webkit-transform: translateX(5px) rotate(5deg);
              transform: translateX(5px) rotate(5deg);
    }

    90% {
      -webkit-transform: translateX(-5px) rotate(-5deg);
              transform: translateX(-5px) rotate(-5deg);
    }

    100% {
      -webkit-transform: translateX(0) rotate(0);
              transform: translateX(0) rotate(0);
    }
  }

  /*.conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }*/

  .storyCon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .storyConP {
    color: #5f758e;
    text-align: left;
    font-size: 70px;
    text-transform: uppercase;
  }

  .storyConIn {
    font-size: 45px;
    width: 55%;
    color: white;
    z-index: 1000;
  }

  .storyConIn p {
    color: white;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .book {
    width: 20%;
    margin-top: -4%;
    -webkit-animation: floating 6s ease-in-out infinite;
            animation: floating 6s ease-in-out infinite;
    margin-left: 55%;
    cursor: pointer;
  }

  #circle {
    position: fixed;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s, border-radius 0.5s;
    z-index: 9999;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {}


  .animate.animate {
    background-size: 100% 100%;

  }

  .animate2 {
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon2Div {
    color: rgb(255, 255, 255);
    text-align: left;
    font-family: "Burbank Big Condensed";
    font-size: 250px;
  }

  .storyCon2Div2 {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 80px;
    text-align: center;
    margin-top: 50px;
  }


  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  .mintDiv {
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
  }

  .mintCon1 {
    color: black;
    font-family: "Burbank Big Condensed";
    font-size: 180px;
    line-height: 180px;
    width: 90%;
    text-transform: uppercase;
  }

  .mintCon2 {
    color: black;
    width: 60%;
    font-weight: bolder;
    margin-top: 5px;
    font-size: 45px;
  }

  .basketBall {
    width: 350px;
    margin-left: 70%;
  }

  .basketBallDiv {
    width: 100%;
  }

  .mintBox {
    background-color: #f9e6d6;
    border-radius: 40px;
    border: 12px solid black;
    width: 45%;
    margin-left: 60px;
    padding: 15px;
  }

  .mintBox--1 {
    border-radius: 100px;
    width: 45%;
    margin-left: 60px;
    padding: 15px;
  }

  .mintBtn {
    font-size: 130px;
    background-color: #8a6d57;
    color: white;
    width: 100%;
    border: 12px rgb(0, 0, 0) solid;
    border-radius: 22px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: 40px;
    padding-bottom: 10px;
  }
  
  .mintBtn--2 {
    font-size: 130px;
    background-color: #ffffff;
    color: #8a6d57;
    width: 100%;
    border: 12px rgb(0, 0, 0) solid;
    border-radius: 22px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
     margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    padding-top: -400px;
    padding-bottom: 10px;
  }


  .mintBtn:hover {
    background-color: #ffffff;
    color: #8a6d57;
  }


  .mintBox2 {
    padding: 20px;
  }

  .mintBox2--2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10vh;
    padding: 20px;
  }

  .slideImg {
    position: relative;
    width: 1550px;
    height: 1550px;
    overflow: hidden;
    border-radius: 40px;
    border: 12px solid black;
  }

  @-webkit-keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes waviy {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes waviy-S {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    50% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-animation: slideAnimation 10s infinite;
            animation: slideAnimation 10s infinite;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @-webkit-keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }

  @keyframes slideAnimation {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }

    20% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }

    40% {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }

    80% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }

    100% {
      -webkit-transform: translateY(-400%);
              transform: translateY(-400%);
    }
  }


  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 160px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);

  }

  .totalSupply2 {
    text-align: center;
    font-size: 110px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 1%;

  }

  .price {
    text-align: center;
    font-size: 55px;
    color: rgb(0, 0, 0);

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    margin-top: 110px;
    margin-bottom: 130px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;

  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;


  }

  .btnfos-0-2 {
    font-size: 90px;
    margin-left: 90px;
    margin-right: 90px;
    width: 200px;
    height: 200px;
    border: none;
    border-radius: 100px;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: black 8px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;


    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: "Burbank Big Condensed";
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    font-size: 350px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 350px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 5px black;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    color: white;
    text-align: center;
    text-align: center;
  }

  .mintbuttondiv {
    text-align: center;

  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 50px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 40px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 60px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 60px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: "Burbank Big Condensed";
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 16px;
  }

  summary {
    font-size: 58px;
    color: rgb(0, 0, 0);
    padding: 6rem;
    margin-bottom: 8rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 300px;
    box-shadow: 0 8px #c7aaaa;
    border: 2px #967777 solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: #3b2519b2;
    margin-bottom: 5rem;
    text-align: left;
    font-size: 58px;
    font-weight: bold;
    margin-left: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding-top: 160px;
    padding-bottom: 160px;
    background-color: #2f3a47;
  }

  .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding: 30px;
    background-color: #000000;
  }

  .footerH {
    font-family: "Burbank Big Condensed";
    z-index: 1;
    font-size: 350px;
    color: white;
    font-family: "Burbank Big Condensed";
    line-height: 350px;
    z-index: 1;
    text-shadow: black 9px 9px 7px;
    -webkit-text-stroke: 5px black;
    z-index: 1;
    text-align: center;

  }

  .socialImg {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
  }

  .socialImg img {
    width: 160px;
    height: 160px;
    cursor: pointer;
    -webkit-animation: zoom-in-zoom-out 1.5s ease infinite;
            animation: zoom-in-zoom-out 1.5s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .follow {
    font-family: "Burbank Big Condensed";
    color: white;
    font-size: 140px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 100px;
    padding-right: 100px;
  }

  .animate3 {
    /*background: rgb(87, 102, 244);
  background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    background-color: #516479;
    border: #34404d 11px solid;
    border-radius: 24px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate3.animate3 {
    background-size: 100% 100%;
  }


  :root {
    --headingSize: 55px;
    --background-color: black;
  }

  h1 {
    margin: 0;
    letter-spacing: -1px;
    font-size: var(--headingSize);
  }

  .container {
    width: 50%;
  }

  span {
    display: inline-block;
    overflow: hidden;
    -webkit-animation: .6s swift-up ease-in-out forwards;
            animation: .6s swift-up ease-in-out forwards;
  }

  i {
    font-style: normal;
    position: relative;
    top: var(--headingSize);
    -webkit-animation: .8s swift-up ease-in-out forwards;
            animation: .8s swift-up ease-in-out forwards;
    font-family: "Burbank Big Condensed";

  }

  @-webkit-keyframes swift-up {
    to {
      top: 0;
    }
  }

  @keyframes swift-up {
    to {
      top: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .container {
      width: auto;
      margin: 0 10%;
    }

    :root {
      --headingSize: 35px;
    }
  }

  .connect2Mob {
    display: none;
  }
}
